import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"

export default function Template({ data }) {
  const news = data.markdownRemark

  return (
    <>
      <Seo title={news.frontmatter.title} />
      <h1>{news.frontmatter.title}</h1>
      <hr />
      <small>Published {news.frontmatter.date}</small>
      <div
        dangerouslySetInnerHTML={{ __html: news.html }}
        className="news-page"
      />
    </>
  )
}

export const newsQuery = graphql`
  query NewsByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date
        title
      }
    }
  }
`
